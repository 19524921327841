/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const CountryPage: React.FC = () => {
  const [countryData, setCountryData] = useState<any>({});

  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setCountryData(response?.countries);
    }
    fetchCountryData();
  }, []);

  if (!countryData) {
    return <Loading />;
  }

  return (
    <div>
      {!!countryData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>Country Page</h3>
          <ul>
            {countryData.map((item: any) => (
              <div key={item.id}>
                <li className="mb-0 last:mb-4">
                  {item.id} - {item.countryName}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CountryPage;
