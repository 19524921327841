/* eslint-disable @typescript-eslint/no-explicit-any */

import Workflow from './base';

class Metadata extends Workflow {
  constructor() {
    super('metadatawf');
    this.setDirectBaseUrl(
      'https://ri-d-usc-dom-metadata-wf-aca.gentlebay-a25ffb7b.centralus.azurecontainerapps.io'
    );
  }

  async getCountryData() {
    this.setAction('getCountry');
    this.setDiirectEndPoint(`/Metadata/Countries`);
    const res: any = await this.getCall();

    return res?.data?.wfRes || res?.data || undefined;
  }

  async getAreaFocusData() {
    this.setAction('getAreaFocus');
    this.setDiirectEndPoint(`/Metadata/AreaOfFocuses`);
    const res: any = await this.getCall();

    return res?.data?.wfRes || res?.data || undefined;
  }

  async getSharingPermissionData() {
    this.setAction('getSharingPermission');
    this.setDiirectEndPoint(`/Metadata/SharingPermissions`);
    const res: any = await this.getCall();

    return res?.data?.wfRes || res?.data || undefined;
  }

  async getStateData(countryCode: string) {
    this.setAction('getState');
    this.setData({
      countryCode,
    });
    this.setDiirectEndPoint(
      `/Metadata/StatesByCountryName?countryName=${countryCode}`
    );
    const res: any = await this.getCall();

    return res?.data?.wfRes || res?.data || undefined;
  }

  async getLanguageData() {
    this.setAction('getLanguage');
    this.setDiirectEndPoint(`/Metadata/Languages`);
    const res: any = await this.getCall();

    return res?.data?.wfRes || res?.data || undefined;
  }

  async getExpertiseLevels() {
    this.setAction('getExpertiseLevels');
    this.setDiirectEndPoint(`/Metadata/ExpertiseLevels`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getTerminationReasons() {
    this.setAction('getTerminationReasons');
    this.setDiirectEndPoint(`/Metadata/TerminationReasons`);
    const res: any = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getExpertiseAreas() {
    this.setAction('getExpertiseAreas');
    this.setDiirectEndPoint(`/Metadata/ExpertiseAreas`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getTimezones() {
    this.setAction('getTimezones');
    this.setDiirectEndPoint(`/Metadata/Timezones`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getAllRoles() {
    this.setAction('getAllRoles');
    this.setDiirectEndPoint(`/Metadata/AllRoles`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getProgram() {
    this.setAction('getProgram');
    this.setDiirectEndPoint(`/Metadata/Programs`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getLeadershipRoles() {
    this.setSwitch('direct');
    this.setAction('getLeadershipRoles');
    this.setDiirectEndPoint(`/Metadata/LeadershipRoles`);
    const res = await this.getCall();
    this.setSwitch('app');
    return res?.data?.wfRes || res?.data || undefined;
  }
}

export default Metadata;
